<template>
  <div>
    <b-form @submit.prevent="save">
      <div class="title-actions">
        <h5 class="title">{{ tournament.name }} - {{ title }}</h5>
        <div class="btns">
          <b-button @click="$router.go(-1)">
            <i class="fa fa-arrow-left"></i>
          </b-button>
        </div>
      </div>
      <hr>
      <div class="form-group">
          <label for="division">{{ $t('division') }}</label>
          <select name="division" id="division" class="form-control" v-validate="'required'" :state="validateState('division')" v-model="payload.division_id" @change="getSeasons()">
              <option v-for="(division, divInd) in divisions" :key="divInd" :value="division.id">{{ division.name }}</option>
          </select>
          <span class="error-inputs">{{ errors.first('division') }}</span>
      </div>
      <div class="form-group">
          <label for="season">{{ $tc('season', 1) | toCapitalize }}</label>
          <select name="season" id="season" class="form-control" v-validate="'required'" :state="validateState('season')" v-model="payload.season_id" @change="getTeams()">
              <option v-for="(season, seaInd) in seasons" :key="seaInd" :value="season.id">{{ season.name }}</option>
          </select>
          <span class="error-inputs">{{ errors.first('season') }}</span>
      </div>
      <div class="form-group">
          <label for="team">{{ $t('teams') }}</label>
          <select name="team" id="team" class="form-control" v-validate="'required'" :state="validateState('team')" v-model="payload.team_id">
              <option v-for="(team, teaInd) in teams" :key="teaInd" :value="team.id">{{ team.name }}</option>
          </select>
          <span class="error-inputs">{{ errors.first('team') }}</span>
      </div>
      <b-button type="submit" variant="primary" :disabled="loading">{{$t('deliverPrizes')}}</b-button>
      <b-button class="ml-3" @click="$router.go(-1)">{{$t('return')}}</b-button>
    </b-form>
    <b-modal id="modalConfirmDelivery" :title="$t('confirm')" no-close-on-backdrop>
    <h5 v-html="$t('awardsConfirmText')"></h5>
    <div slot="modal-footer">
      <b-button @click="$root.$emit('bv::hide::modal', 'modalConfirmDelivery')">{{$t('cancel')}}</b-button>
      <b-button variant="primary" class="ml-2" @click="deliverPrize()">{{$t('btnIfContinue')}}</b-button>
    </div>
  </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      title: '',
      tournament: {},
      tournamentId: 0,
      divisions: [],
      seasons: [],
      teams: [],
      payload: {
        division_id: 0,
        season_id: 0
      }
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console'
    ])
  },
  mounted() {
    const tournamentId = this.$route.params.tournamentId
    this.tournamentId = tournamentId
    this.changeTitleBar(this.capitalize(this.$tc('award', 2)))
    this.title = this.capitalize(this.$tc('award', 2))
    this.fetchData()
  },
  methods: {
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          this.$root.$emit('bv::show::modal', 'modalConfirmDelivery')
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    deliverPrize () {
      const path = `auth/${this.lang}/${this.console}/tournament/${this.tournamentId}/awards/store`
      this.$axios.post(path, this.payload).then(() => {
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
        this.$root.$emit('bv::hide::modal', 'modalConfirmDelivery')
      })
    },
    fetchData () {
      const path = `auth/${this.lang}/${this.console}/tournament/${this.tournamentId}/awards/delivery`
      this.$axios.get(path).then(response => {
          const data = response.data
          this.divisions = data.divisions
          this.tournament = data.tournament
      }).catch(() => {
        this.$router.go(-1)
      })
    },
    getSeasons () {
      const divisionId = this.payload.division_id
      const path = `auth/${this.lang}/${this.console}/division/${divisionId}/awards/seasons`
      this.$axios.get(path).then(response => {
        this.seasons = response.data.data
      })
    },
    getTeams () {
      const divisionId = this.payload.division_id
      const path = `auth/${this.lang}/${this.console}/division/${divisionId}/awards/teams`
      this.$axios.get(path).then(response => {
        this.teams = response.data.data
      })
    }
  }
}
</script>
